import React from 'react'
import { Link } from 'gatsby'
import '../styles/NextPrev.css'


const NextPrev = ({ next, prev }) => {
    return (
        <div className="NextPrev">
            {next &&
                <div className="NPWrap">
                    <span>次の投稿</span>
                    <Link to={next.fields.slug} className="NPCard"> {next.frontmatter.title}</Link>
                </div>
            }
            {prev &&
                <div className="NPWrap">
                    <span>前の投稿</span>
                    <Link to={prev.fields.slug} className="NPCard">{prev.frontmatter.title}</Link>
                </div>
            }
        </div>
    )
}

export default NextPrev
